import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    AppBar, Toolbar, Typography, Avatar, Menu, MenuItem, IconButton, Drawer, List, ListItem,
    Button, useMediaQuery, useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from "../hooks/useAuthContext";

export default function Navbar({ themeMode, toggleThemeMode }) {
    const { logout } = useLogout();
    const { user } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (menuItem) => {
        setAnchorEl(null);
        if (menuItem === 'Profile') {
            navigate('/profile');
        } else if (menuItem === 'Settings') {
            navigate('/settings');
        } else if (menuItem === 'Logout') {
            logout();
        }
    };

    const handleMobileMenuOpen = () => {
        setMobileMenuOpen(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuOpen(false);
    };

    const renderMobileMenuLink = (path, label) => (
        <ListItem onClick={handleMobileMenuClose} key={label}>
            <Button
                fullWidth
                component={Link}
                to={path}
                style={{ justifyContent: 'left', textTransform: 'none' }}
            >
                {label}
            </Button>
        </ListItem>
    );

    const mobileMenu = (
        <Drawer anchor="right" open={mobileMenuOpen} onClose={handleMobileMenuClose}>
            <List style={{ width: 250 }}>
                {user ? (
                    <>
                        <ListItem>
                            <Avatar
                                onClick={handleMenu}
                                src={user ? user.photoURL : undefined}
                                alt={user ? user.displayName : 'Avatar'}
                                style={{ cursor: 'pointer', marginRight: '16px' }}
                            />
                            <Typography
                                onClick={handleMenu}
                                style={{ cursor: 'pointer', marginRight: '16px' }}
                            >
                                {user ? user.displayName : ''}
                            </Typography>
                        </ListItem>
                        <hr />
                        {renderMobileMenuLink("/", "Home")}
                        {renderMobileMenuLink("/characters", "Characters")}
                        {renderMobileMenuLink("/mytemplates", "My templates")}
                        {renderMobileMenuLink("/templates", "Public templates")}
                        {renderMobileMenuLink("/shared", "Shared templates")}
                        {renderMobileMenuLink("/profile", "Profile")}
                        {renderMobileMenuLink("/settings", "Settings")}
                        <ListItem onClick={logout}>
                            <Button
                                fullWidth
                                style={{ justifyContent: 'left', textTransform: 'none' }}
                            >
                                Logout
                            </Button>
                        </ListItem>
                    </>
                ) : (
                    <>
                        {renderMobileMenuLink("/", "Home")}
                        {renderMobileMenuLink("/templates", "Public templates")}
                        <ListItem>
                            <Button
                                fullWidth
                                component={Link}
                                to="/login"
                                style={{ justifyContent: 'left', textTransform: 'none' }}
                                onClick={handleMobileMenuClose}
                            >
                                Login
                            </Button>
                        </ListItem>
                    </>
                )}
            </List>
        </Drawer>
    );

    return (
        <AppBar position="static" style={{ backgroundColor: 'primary' }}>
            <Toolbar>
                <Avatar
                    src="/daocbuddyfavicon.png"
                    alt="DAoC Buddy Logo"
                    style={{ marginRight: '10px' }}
                />
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        DAoC Buddy
                    </Link>
                </Typography>

                {isMobile ? (
                    <React.Fragment>
                        <IconButton onClick={toggleThemeMode} color="inherit" style={{ marginRight: '10px' }}>
                            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        {user ? (
                            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMobileMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <Button
                                component={Link}
                                to="/login"
                                color="inherit"
                                style={{ textTransform: 'none' }}
                            >
                                Login
                            </Button>
                        )}
                    </React.Fragment>
                ) : (
                    user ? (
                        <React.Fragment>
                            {/*<Link*/}
                            {/*    to="/items"*/}
                            {/*    style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}*/}
                            {/*>*/}
                            {/*    Items*/}
                            {/*</Link>*/}
                            <Link
                            to="/characters"
                            style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                        >
                            My Characters
                        </Link>
                            <Link
                                to="/mytemplates"
                                style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                            >
                                My Templates
                            </Link>
                            <Link
                                to="/templates"
                                style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                            >
                                Public Templates
                            </Link>
                            <Link
                                to="/shared"
                                style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                            >
                                Shared Templates
                            </Link>

                            <IconButton onClick={toggleThemeMode} color="inherit" style={{ marginRight: '10px' }}>
                                {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                            <Avatar
                                onClick={handleMenu}
                                src={user.photoURL || undefined}
                                alt={user.displayName || 'Avatar'}
                                style={{ cursor: 'pointer', marginRight: '16px' }}
                            />
                            <Typography
                                onClick={handleMenu}
                                style={{ cursor: 'pointer', marginRight: '16px' }}
                            >
                                {user.displayName}
                            </Typography>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => handleClose('Profile')}>Profile</MenuItem>
                                <MenuItem onClick={() => handleClose('Settings')}>Settings</MenuItem>
                                <MenuItem onClick={() => handleClose('Logout')}>Logout</MenuItem>
                            </Menu>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Link
                                to="/templates"
                                style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                            >
                                Public Templates
                            </Link>
                            <Link
                                to="/login"
                                style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                            >
                                Login
                            </Link>
                        </React.Fragment>

                    )
                )}
            </Toolbar>
            {mobileMenu}
        </AppBar>
    );
}
