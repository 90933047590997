import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Navigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { useAuthContext } from "./hooks/useAuthContext";
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import {Box, Container} from "@mui/material";
import Templates from "./pages/templates/Templates";
import Template from "./pages/templates/Template";
import TemplateEdit from "./components/templates/edit/TemplateEdit";
import TemplateView from "./components/templates/view/TemplateView";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Profile from "./pages/settings/Proflie";
import Admin from "./pages/admin/Admin";
import Settings from "./pages/settings/Settings";
import ItemAdd from "./components/Admin/Items/ItemAdd";
import Characters from "./pages/characters/Characters";
import MyTemplates from "./pages/mytemplates/MyTemplates";
import CharacterPlanner from "./pages/planner/CharacterPlanner";
import SharedTemplates from "./pages/shared/SharedTemplates";
import GaherisCharacters from "./pages/gaheris/characters/GaherisCharacters";
import Items from "./pages/items/Items";

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#36baa2',
        },
    },
});


function App() {
    const { user, authIsReady } = useAuthContext()
    const [themeMode, setThemeMode] = useState('dark');

    const toggleThemeMode = () => {
        const newMode = themeMode === 'light' ? 'dark' : 'light';
        localStorage.setItem('themeMode', newMode);
        setThemeMode(newMode); // Assuming you have a state to manage this
    };

    useEffect(() => {
        const savedThemeMode = localStorage.getItem('themeMode');
        if (savedThemeMode) {
            setThemeMode(savedThemeMode);
        }
    }, []);

    return (
        <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
            <CssBaseline />
            {authIsReady && (
                <Router>
                        <Box display="flex" flexDirection="column" minHeight="100vh"> {/* Flex container */}
                            <Navbar themeMode={themeMode} toggleThemeMode={toggleThemeMode} />
                            <Container component="main" maxWidth={false} sx={{ flexGrow: 1, mb: 2, mt: 2 }}>
                                <Routes>
                                    <Route path="/" element={<Home /> } />
                                    <Route path="/planner" element={user ? <CharacterPlanner /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/characters" element={user ? <Characters /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/gaheris/characters" element={user ? <GaherisCharacters /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/mytemplates" element={user ? <MyTemplates /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/items" element={<Items />} />
                                    <Route path="/templates" element={<Templates />} />
                                    <Route path="/templates/:id" element={<Template />} />
                                    <Route path="/templates/:id/edit" element={user ? <TemplateEdit /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/templates/:id/view" element={<TemplateView />} />
                                    <Route path="/shared" element={user ? <SharedTemplates /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/profile" element={user ? <Profile /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/settings" element={user ? <Settings /> : <Navigate replace to={"/login"} />} />
                                    <Route path="/admin" element={user ? <Admin /> : <Navigate replace to={"/login"} />}>
                                        {/*<Route index element={<ItemsPage />} />*/}
                                        {/*<Route path="items" element={user ? <ItemsPage /> : <Navigate replace to={"/login"} />} />*/}
                                        {/*<Route path="items/:id" element={user ? <ItemView /> : <Navigate replace to={"/login"} />} />*/}
                                        <Route path="items/add" element={user ? <ItemAdd /> : <Navigate replace to={"/login"} />} />
                                        {/*<Route path="shared-templates" element={<SharedTemplatesAdmin />} />*/}

                                    </Route>
                                    <Route path="/login" element={!user ? <Login /> : <Navigate replace to={"/"} />} />
                                </Routes>
                            </Container>
                            <Footer />
                        </Box>
                </Router>
            )}
        </ThemeProvider>
    );
}

export default App;
